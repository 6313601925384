import { template as template_7dc39e0bd9cb45198e85eb6e1a0e3cda } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKField from "discourse/form-kit/components/fk/field";
import FKFieldset from "discourse/form-kit/components/fk/fieldset";
const FKCheckboxGroup = template_7dc39e0bd9cb45198e85eb6e1a0e3cda(`
  <FKFieldset
    class="form-kit__checkbox-group"
    @title={{@title}}
    @description={{@description}}
  >
    {{yield
      (hash
        Field=(component
          FKField
          errors=@errors
          addError=@addError
          data=@data
          set=@set
          remove=@remove
          registerField=@registerField
          unregisterField=@unregisterField
          triggerRevalidationFor=@triggerRevalidationFor
          showMeta=false
          showTitle=false
        )
      )
    }}
  </FKFieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKCheckboxGroup;
