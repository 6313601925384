import { template as template_f32de2787178430c8ba0ff8affc0eb2e } from "@ember/template-compiler";
const FKControlMenuContainer = template_f32de2787178430c8ba0ff8affc0eb2e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
