import { template as template_daa4f2e225bd4f66adf97fe4ad3bc3f3 } from "@ember/template-compiler";
const FKText = template_daa4f2e225bd4f66adf97fe4ad3bc3f3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
