import { template as template_133cb9ba905f48b695c4510a2e1ce9cb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_133cb9ba905f48b695c4510a2e1ce9cb(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
